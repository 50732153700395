import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { Nil } from '@model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class WebsocketService {
  private hubConnection: HubConnection | Nil;
  public webSocketReconnectionSubject$ = new Subject<void>();
  public webSocketStartSubject$ = new Subject<void>();
  public initialize(url: string, token: string): void {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => {
          return token;
        },
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    this.hubConnection.start().then(() => {
      this.webSocketStartSubject$.next();
    });

    this.hubConnection.onreconnected(() => {
      this.webSocketReconnectionSubject$.next();
    });
  }

  public destroy(): void {
    this.hubConnection?.stop();
  }

  public on<Response>(methodName: string): Observable<Response> {
    return new Observable((observer) => {
      this.hubConnection?.on(methodName, (message) => {
        observer.next(message);
      });
    });
  }
}
